$(document).ready(function () {
  // fix header
  $(window).scroll(function () {
    $(".header").toggleClass("fixed", $(window).scrollTop() > 70);
  });

  // mobile popup menu
  $(".header__toggle").on("click", function (e) {
    $(".header__toggle").toggleClass("open");
    $(".header__nav").toggleClass("open");
    e.preventDefault();
  });

  // header language and currency selectors
  document.addEventListener("click", (e) => {
    const selectors = Array.from(document.querySelectorAll(".header-dropdown.open"));
    selectors.forEach((select) => {
      if (e.target.parentNode != select) {
        select.classList.remove("open");
      }
    });
  });
  const dropdowns = Array.from(document.querySelectorAll(".header-dropdown__toggle"));
  if (dropdowns.length > 0) {
    dropdowns.forEach((dropdown) => {
      dropdown.addEventListener("click", (e) => {
        e.preventDefault;
        dropdown.parentNode.classList.toggle("open");
      });
    });
  }

  $(".freeze-table").freezeTable({
    freezeHead: true,
    fastMode: true,
  });

  const clickableRows = Array.from(document.querySelectorAll(".row-link"));
  if (clickableRows.length > 0) {
    clickableRows.forEach((row) => {
      row.addEventListener("click", (e) => {
        const target = row.getAttribute("data-href");
        if (target && !e.target.classList.contains("btn")) {
          window.location = target;
        }
      });
    });
  }

  // Accordion FAQ
  const faqItemsBtns = Array.from(document.querySelectorAll(".faq-card__header"));
  const faqItems = Array.from(document.querySelectorAll(".faq-list__item"));
  if (faqItems.length > 0) {
    faqItemsBtns.forEach((button) => {
      button.addEventListener("click", (e) => {
        e.preventDefault();
        button.parentNode.parentNode.classList.toggle("open");
        const faqContent = button.parentNode.querySelector(".faq-card__body");
        $(faqContent).slideToggle("fast", function () {});
      });
    });
  }

  $('.popup-toggler').on('click', function (e) {
    e.preventDefault();
    const t = document.getElementById($(this).attr('data-id'));
    if (t) {
      t.classList.toggle("visible");
      document.body.classList.toggle("noscroll");
    }
  })


  $('.home-nav__switch-radio').on('click', function (e) {
    e.preventDefault();
    if (!$(this).hasClass('selected')) {
      $('.home-nav__switch-radio').each(function () {
        $(this).toggleClass('selected');
      })

      $('.home-nav__parent').each(function () {
        $(this).toggleClass('hidden');
      })
    }
  })

  $('.add-line').on('click', function (e) {
    console.log("works");
    var list = jQuery(this).prev(jQuery(this).attr('data-list-selector'));
    // Try to find the counter of the list or use the length of the list
    var counter = list.data('widget-counter') || list.children().length;
    // grab the prototype template
    var newWidget = list.attr('data-prototype');
    // replace the "__name__" used in the id and name of the prototype
    // with a number that's unique to your emails
    // end name attribute looks like name="contact[emails][2]"
    newWidget = newWidget.replace(/__name__/g, counter);
    // Increase the counter
    counter++;
    // And store it, the length cannot be used if deleting widgets is allowed
    list.data('widget-counter', counter);
    // create a new list element and add it to the list
    //var newElem = jQuery(list.attr('data-widget-tags')).html(newWidget);
    $(newWidget).appendTo(list);
  });

  $(document).on('click', '.js-remove-buttons', function(e) {
    e.preventDefault();
    $(this).parent().fadeOut().remove();
  });
});
