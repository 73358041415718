jQuery(document).ready(function(){


    $(document).on('change', 'form.js-submit input, form.js-submit select, form.js-submit textarea', function(e) {
        $('form.js-submit').submit();
    });

    $('#finish_isCompany').change(function(e) {
        if($(this).is(':checked')){
            $('.for-company').slideDown();
            $('input, select, textarea', '.for-company').removeAttr('disabled');
        } else {
            $('.for-company').slideUp();
            $('input, select, textarea', '.for-company').attr('disabled', 'disabled');
        }
    }).trigger('change');

    jQuery('form[name="delivery"]').on('submit', function (e) {
        e.preventDefault();
        var $form = $(e.currentTarget);
        jQuery.ajax({
            url: $form.attr('action'),
            method: 'POST',
            data: $form.serialize(),
            success: function (response) {
                if (response.success) {
                    if (response.recap !== '') {
                        jQuery('.shopping-recap__right').html(response.recap);
                    }
                    if (response.openPacketa === true) {
                        openPacketa(true);
                    } else {
                        if (response. packetaType !== true) {
                            var text = jQuery('.packeta-select-toggle').attr('data-name');
                            jQuery('.packeta-select-toggle').find('.method-item__label').text(text);
                        }
                    }
                }
            }
        });
    })

    $(document).on('click', '.delivery-change', function (e) {
        e.preventDefault();
        e.stopPropagation();
        openPacketa(false);
    })
});

function openPacketa(changeText) {
    const packetaApiKey = 'b938d667d17dcea8';

    const packetaOptions = {
        country: "cz",
    };

    const parentEl = jQuery('.packeta-select-toggle');
    var label = parentEl.find('.method-item__label');
    var text = parentEl.attr('data-name');
    parentEl.addClass('current-delivery-packeta');
    if (changeText) {
        label.text(text + ' - ' + ' ').append(parentEl.attr('data-select-text') + ' <button class="delivery-change">' + parentEl.attr("data-select-button") + '</button>');
    }

    Packeta.Widget.pick(packetaApiKey, showSelectedPickupPoint, packetaOptions)
}
